import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

const Layout = ({ children, intl }) => (
  <div
    style={{
      paddingTop: 40,
      margin: 0,
      /* with: "100vh",
      height: "100vh", */
    }}
  >
    {children}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
